import React from "react"
import FIP from "./models/fip";
import FIPTileInner from "./fip-tile-inner";
import { FIPMessageTypes } from "./messages/fip-messages";
import {AnimatePresence, motion} from 'framer-motion'

interface IFIPTileProps {
      fip: FIP;
      currentWork: any;
      removeFromColumn:  (stationId: number, frameSerialId: string) => void;
      getColumnPosition:  (stationId: number) => number;
      addFrameToColumn:  (stationId: number, frame: FIP ) => void;
}


// Wait for the slide entrance to complete before exiting
/*
const SlideInAndFadeout = () => {
  const [didEnter, setDidEnter] = React.useState(false)
  const onDidEnter = () => setDidEnter(true)
  return (
    <FadeOut active={didEnter}>
      <SlideInDown onComplete={onDidEnter}>🥧</SlideInDown>
    </FadeOut>
  )
}
*/
const FIPTile2 = (props: IFIPTileProps) => {


      return (
            <>
            
            { props.fip.isVisible === true && props.fip.workState === FIPMessageTypes.FRAME_START.valueOf()  && 
                        <motion.div 
                            initial={{ opacity: 0, scale: 1.25 }}
                            animate={{ opacity: 1, scale: 1 }}
                            transition= {{
                              duration: 4,
                              delay: 0.5,
                              ease: "easeIn"
                            }}
                      >
                        <FIPTileInner key={ props.fip.serialId} fip={props.fip} currentWork={null} />               
                  </motion.div>
            }

            {props.fip.isVisible === true && props.fip.workState === FIPMessageTypes.FRAME_REJECTED_HERE.valueOf() && 
                  <motion.div 
                        initial={{ opacity: 0, scale: 0 }}
                        animate={{ opacity: 1, scale: 1}}
                        transition={{
                              duration: 4,
                              delay: 0.5,
                              ease: "easeIn"
                        }}
                  >
                        <FIPTileInner key={ props.fip.serialId} fip={props.fip} currentWork={null} />               
                  </motion.div>
            }
                  {  /* onComplete={ () => props.removeFromColumn(props.fip.curStationId, props.fip.serialId)}> */ }
            { props.fip.isVisible === true && ( props.fip.workState === FIPMessageTypes.FRAME_END.valueOf() || props.fip.workState === FIPMessageTypes.FRAME_REJECTED.valueOf() ) && 
                  <AnimatePresence>
                        <motion.div
                              initial={{
                                          x: 0, 
                                          opacity: 1, 
                                          scale: 1
                                    }}
                              animate={{
                                    x:  (props.getColumnPosition(props.fip.nextStationId!) -  props.getColumnPosition( props.fip.curStationId)), 
                                    opacity: 1, 
                                    scale: .75,
                                    transition: {
                                          duration: 5,
                                          delay: 1
                                    }

                              }}
                              exit={{ opacity: 0 }}
                              onAnimationComplete={definition => {
                                    props.removeFromColumn(props.fip.curStationId, props.fip.serialId)
                              }}
                              >
                              <FIPTileInner key={ props.fip.serialId} fip={props.fip} currentWork={null} />            
                        </motion.div>

                  </AnimatePresence>

            } 

            {props.fip.isVisible === true &&  ( props.fip.workState === FIPMessageTypes.FRAME_VOIDED.valueOf() ) && 

                  <motion.div 
                        initial={{ opacity: 1, scale: 1 }}
                        animate={{ opacity: 0, scale: 0 }}
                        transition={{
                        duration: 5,
                        delay: 10,
                        ease: "easeOut"
                        }}
                        onAnimationComplete={definition => {
                              // frame was voided, so no longer display on sceen after (inner) animation completes
                              props.removeFromColumn(props.fip.curStationId!, props.fip.serialId)
                        }}
                  >
                        <FIPTileInner key={ props.fip.serialId} fip={props.fip} currentWork={null} />               
                  </motion.div>
            }

            {props.fip.isVisible === true &&  (  props.fip.workState === FIPMessageTypes.FRAME_UNVOIDED.valueOf() ) && 

                  <motion.div 
                        initial={{ opacity: 0, scale: 0 }}
                        animate={{ opacity: 1, scale: 1 }}
                        transition={{
                              duration: 5,
                              delay: 0.5,
                              ease: "easeIn"
                        }}
                  >
                        <FIPTileInner key={ props.fip.serialId} fip={props.fip} currentWork={null} />               
                  </motion.div>
            }

              {/*    <FadeIn durationMs={4000} iterations={1} >                   </FadeIn>*/}

            { props.fip.isVisible === true && ( props.fip.workState === FIPMessageTypes.ADDED_FROM_OTHER_STATION.valueOf() ) &&        

                        <motion.div 
                              initial={{ opacity: 0, scale: 0 }}
                              animate={{ opacity: 1, scale: 1}}
                              transition={{
                                    duration: 4,
                                    delay: 0.5,
                                    ease: "easeIn"
                              }}
                        >
                              <FIPTileInner key={ props.fip.serialId} fip={props.fip} currentWork={null} />               
                        </motion.div>

            }

            {/* this frame does not move. It is already in the correct column, marked as started.  
               Only need to higlight that it was canceled.  It can stay marked as long as needed until state changes again */}
            { props.fip.isVisible === true && ( props.fip.workState === FIPMessageTypes.FRAME_START_CANCELED.valueOf() ) &&        
            
                  <motion.div 
                  initial = {{opacity: 0}}
                  animate = {{opacity: 1}}

                  transition={{ 
                        duration: 1,
                        delay: 0,
                        ease: "easeIn"
                  }} 
                 
                  >
                        <FIPTileInner key={ props.fip.serialId} fip={props.fip} currentWork={null}  addFrameToColumn={props.addFrameToColumn}  removeFromColumn={props.removeFromColumn} />               
                  </motion.div>
            }
            {props.fip.isVisible === true &&  ( props.fip.workState === FIPMessageTypes.FRAME_CREATED.valueOf() ) &&        
                  <motion.div 
                  initial = {{opacity: 0, scale: 0}}
                  animate = {{opacity: 1, scale: 1}}

                  transition={{
                        duration: 4,
                        delay: 0.5,
                        ease: "easeIn"
                        }}>
                        <FIPTileInner key={ props.fip.serialId} fip={props.fip} currentWork={null} />               
                  </motion.div>
            }

             { props.fip.isVisible === true && !props.fip.workState  &&        
                  <FIPTileInner key={ props.fip.serialId} fip={props.fip} currentWork={null} />               
            } 
            </>
      );



}

export default FIPTile2;