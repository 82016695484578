import React, { useState, useEffect, useCallback, useRef } from 'react';
import './App.css';
import axios from 'axios'
import FIP from './models/fip';
import { StationsEnum } from './station-enums';
import { IStationColumn } from './models/station-column';
import { io, ManagerOptions, SocketOptions } from "socket.io-client";
import { FrameTypesEnum } from './models/frame-types-enum';
import ActiveBatches from './active-batch';
import { ActiveBatch } from './models/active-batches/active-batch';
import { SOCKET_CHANNELS } from './channels/channels';
import { BatchState, CuttingStationBatchItemMessage, CuttingStationBatchMessage, CuttingTicketMessage, MessageTypes } from './messages/cutting-messages';
import { BatchItem } from './models/active-batches/batch-item';
import { FIPMessage, FIPMessageTypes } from './messages/fip-messages';
import Config from './config';
import FloatingSearchBar from './floating-search-bar';
import { StationMessage, StationMessageTypes } from './messages/station-messages';
import { StationActivity } from './models/station-activity';
import { ConnectionStatusEnum } from './models/connection-status-enum';
import ConnectionIndicator from './connection-indicator';
import StationColumn2 from './station-column2';
import { InventoryMessageTypes, InventoryUpdateMessage } from './messages/inventory-update-message';
import { MessageBase } from './messages/message-base';
import { IStationColumnTotals } from './station-column-totals';
import TodaysTally from './todays-tally';
import BoxingPrepTotals from './boxing-prep';
import { BoxingPrepMessage, BoxingPrepMessageTypes } from './messages/box-prep-messages';
import PendingCuttingTicket from './pending-cutting-ticket';


//const socket = io("ws://" + ip + ":3001/fip");
//const socket = io("ws://" + ip + ":3001/wss_gateway");
const connectionOptions : Partial<ManagerOptions & SocketOptions & any> = {
      reconnection: true,
      /**
       * How many reconnection attempts should we try?
       * @default Infinity
       */
 //     reconnectionAttempts: number; 
       /**
       * The time delay in milliseconds between reconnection attempts
       * @default 1000
       */
      reconnectionDelay: 5000,
      /**
       * The max time delay in milliseconds between reconnection attempts
       * @default 5000
       */
//      reconnectionDelayMax: number;
      /**
       * Used in the exponential backoff jitter when reconnecting
       * @default 0.5
       */
  //    randomizationFactor: number;
      /**
       * The timeout in milliseconds for our connection attempt
       * @default 20000
       */
//      timeout: number;
      /**
       * Should we automatically connect?
       * @default true
       */
        autoConnect: false,
      /**
       * the parser to use. Defaults to an instance of the Parser that ships with socket.io.
       */
  //    parser: any;
      
  
      query: {
            //if the client has an id already, reuse it, otherwise send '' and let the server assign a short UUID to the client (then save it for reuse)
            socketId: getExistingSocketId()
      }
};


// each device will connect to the server and send its unique id so that information can be resent.  To do so the client needs a unique id for tracking, so using localstorage to 'send' the clients id to the server once it is set.
function getExistingSocketId() {
      if (typeof(Storage) !== "undefined") {
            let clientId  = localStorage.getItem("deco-tv-fip-app-client-id");
            return clientId; ///`${clientId}&`;
      } else {
            return ''
      }
}

// when the client first connects to the server, use the server's assigned client/socket id as this client's instance id and save it for reuse
function setSocketId( id: string ) {
      if (typeof(Storage) !== "undefined") {
            /// only save the new id if one doesn't already exist
            let currentId = getExistingSocketId();
            if ( !currentId ){
                  localStorage.setItem("deco-tv-fip-app-client-id", id );
            }
      }
}


const socket = io( Config.baseUrl!, connectionOptions);

let frameType: FrameTypesEnum = FrameTypesEnum.premiere;

function App() {

      const [isConnected, setIsConnected] = useState(socket.connected);

      const [forcePageReload, setForcePageReload] = useState<boolean>( false );

      const [lastPong, setLastPong] = useState<string>("");

      const [activeBatches, setActiveBatches] = React.useState<ActiveBatch[]>([]) // shared between premiere and alloy

      const [stationActivity, setStationActivity] = React.useState<StationActivity[]>([])

      const [cuttingLowPriorityTicketsTotal, setCuttingLowPriorityTicketsTotal] = React.useState<number>( 0 );
      const [cuttingHighPriorityTicketsTotal, setCuttingHighPriorityTicketsTotal] = React.useState<number>( 0 );

      const [cutting, setCutting] = React.useState<FIP[]>([])
      const [boring, setBoring] = React.useState<FIP[]>([])
      const [coloring, setColoring] = React.useState<FIP[]>([])
      const [pinsAndMagnets, setPingsAndMagnets] = React.useState<FIP[]>([])
      const [qc, setQC] = React.useState<FIP[]>([])
      const [boxing, setBoxing] = React.useState<FIP[]>([])
      const [shipping, setShipping] = React.useState<FIP[]>([])

      const [milling, setMilling] = React.useState<FIP[]>([])
      const [finalPrep, setFinalPrep] = React.useState<FIP[]>([])

      const [premiereFrameCompleted, setPremiereFrameCompleted] = React.useState<FIP[]>([])
      const [alloyFrameCompleted, setAlloyFrameCompleted] = React.useState<FIP[]>([])

      const [cuttingTotals, setCuttingTotals] = React.useState<IStationColumnTotals>()
      const [boringTotals, setBoringTotals] = React.useState<IStationColumnTotals>()
      const [coloringTotals, setColoringTotals] = React.useState<IStationColumnTotals>()
      const [pinsAndMagnetsTotals, setPingsAndMagnetsTotals] = React.useState<IStationColumnTotals>()
      const [qcTotals, setQCTotals] = React.useState<IStationColumnTotals>()
      const [boxingTotals, setBoxingTotals] = React.useState<IStationColumnTotals>()
      const [shippingTotals, setShippingTotals] = React.useState<IStationColumnTotals>()

      const [millingTotals, setMillingTotals] = React.useState<IStationColumnTotals>()
      const [finalPrepTotals, setFinalPrepTotals] = React.useState<IStationColumnTotals>()

      // these completed totals are the same as the number of frames in colum and also agree with the displaed totals overall
      const [premiereFrameCompletedTotals, setPremiereFrameCompletedTotals] = React.useState<IStationColumnTotals>()
      const [alloyFrameCompletedTotals, setAlloyFrameCompletedTotals] = React.useState<IStationColumnTotals>()

      const [premiereTally, setPremiereTally] = React.useState<number>(0)
      const [alloyTally, setAlloyTally] = React.useState<number>(0)

      const [boxingPrepInventoryTotal, setBoxingPrepInventoryTotal] = React.useState<number>(0)

      const [frameType, setFrameType] = React.useState<FrameTypesEnum>()

      const [curDate, setCurDate] = useState(Date.now());
      const [connectionState, setConnectionState] = useState<ConnectionStatusEnum>( ConnectionStatusEnum.disconnected );
      const [connectionTransport, setConnectionTransport] = useState<string>( "" );
      const [connectionError, setConnectionError] = useState<string>( "" )

      const [clientId, setClientId] = useState<string>( "" );

      const [inInventoryMode, setInInventoryMode] = useState<boolean>( false );

      const cuttingColumnRef = useRef<HTMLDivElement | null>(null);
      const coloringColumnRef = useRef<HTMLDivElement | null>(null);
      const boringColumnRef = useRef<HTMLDivElement>(null);
      const pinsAndMagnestsColumnRef = useRef<HTMLDivElement | null>(null);
      const qcColumnRef = useRef<HTMLDivElement | null>(null);
      const boxingColumnRef = useRef<HTMLDivElement | null>(null);
      const shippingColumnRef = useRef<HTMLDivElement | null>(null);
      const millingColumnRef = useRef<HTMLDivElement | null>(null);
      const finalPrepColumnRef = useRef<HTMLDivElement | null>(null);
      const premiereFrameCompletedColumnRef = useRef<HTMLDivElement | null>(null);
      const alloyFrameCompletedColumnRef = useRef<HTMLDivElement | null>( null );


      const allStores = [
            { stationId: StationsEnum.cutting, store: cutting, setHook: setCutting },
            { stationId: StationsEnum.boring, store: boring, setHook: setBoring },
            { stationId: StationsEnum.coloring, store: coloring, setHook: setColoring },
            { stationId: StationsEnum.pinsAndMagnets, store: pinsAndMagnets, setHook: setPingsAndMagnets },
            { stationId: StationsEnum.qualityControl, store: qc, setHook: setQC },
            { stationId: StationsEnum.boxing, store: boxing, setHook: setBoxing },
            { stationId: StationsEnum.boxingAlloy, store: boxing, setHook: setBoxing },
            { stationId: StationsEnum.shipping, store: shipping, setHook: setShipping },
            { stationId: StationsEnum.milling, store: milling, setHook: setMilling },
            { stationId: StationsEnum.finalPrep, store: finalPrep, setHook: setFinalPrep },
            { stationId: StationsEnum.premiereInventory, store: premiereFrameCompleted, setHook: setPremiereFrameCompleted },
            { stationId: StationsEnum.alloyInventory, store: alloyFrameCompleted, setHook: setAlloyFrameCompleted }];


      //used to test client beind disconnected/reconnected and that values missed are resent

      const [manualDisconnect, setManualDisconnect] = useState<boolean>( false )

      useEffect(() => {
            socket.on('pong', () => {
                  setLastPong(new Date().toISOString());
            });

            socket.on("connect", () => {
                  const engine = socket.io.engine;
                  console.log(engine.transport.name); // in most cases, prints "polling"
                
                  engine.once("upgrade", () => {
                        // called when the transport is upgraded (i.e. from HTTP long-polling to WebSocket)
                        setConnectionTransport( engine.transport.name );
                  });

                  // on connect the client will have sent its previous id to the server for re-use OR will get a first time id to save
                  let existingSocketId = getExistingSocketId();
                  if ( !existingSocketId ) {
                        console.log( "no previous client id found.  will save the one assigned by the server.")
                  }

                  if ( socket.id !== existingSocketId ) {
                        console.log( socket.id);
                        setClientId( socket.id );
                        setSocketId( socket.id); // add it to localstorage for permanent use.
                  }

                  setIsConnected(true);
                  setConnectionState( ConnectionStatusEnum.connected);
                       /* 
                  engine.on("packet", ({ type, data }) => {
                  // called for each packet received
                  });
            
                  engine.on("packetCreate", ({ type, data }) => {
                  // called for each packet sent
                  });
            
                  engine.on("drain", () => {
                  // called when the write buffer is drained
                  });
            
                  engine.on("close", (reason) => {
                  // called when the underlying connection is closed
                  });
                  */
            })
            socket.on("disconnect", (reason) => {
                  setIsConnected(false);
                  setConnectionState( ConnectionStatusEnum.disconnected);
                  setConnectionError ( reason );
                  //https://stackoverflow.com/questions/19162582/socket-io-message-event-firing-multiple-times
                  socket.removeAllListeners('send message');
                  socket.removeAllListeners('disconnect');
//                  socket.io.removeAllListeners('connection');

                  if ((reason === "io server disconnect"  || reason === 'io client disconnect' ) && manualDisconnect === false ) {
                        // the disconnection was initiated by the server or client, so must reconnect manually by design
                        // i.e. there is no auto reconnect for this type of failure in this case

                        setIsConnected( false ); // <== trigger the http calls to reload the data from the server.
                        //socket.connect();  <== this will force a reconnection but data could be lost 
                  }
                      // else the socket will automatically try to reconnect
                 
            });
            // https://socket.io/docs/v4/client-api/#event-reconnect_attempt
            //@ts-ignore
            socket.io.on("error", (error) => {
                  setConnectionError( error.message );
            });

            //@ts-ignore
            socket.io.on("reconnect", () => {
                  setConnectionState( ConnectionStatusEnum.reconnected);
            })
            //@ts-ignore
            socket.io.on("reconnect_attempt", () => {
                  setConnectionState( ConnectionStatusEnum.reconnected);
            })
            
            //@ts-ignore
            socket.io.on("connecting", () => {
                  setConnectionState( ConnectionStatusEnum.connecting);
            })
            //@ts-ignore
            socket.io.on("connect_failed", () => {
                  setConnectionState( ConnectionStatusEnum.connectFailed);
            })
            //@ts-ignore
            socket.io.on("reconnect_failed", () => {
                  setConnectionState( ConnectionStatusEnum.reconnectFailed);
            })
            //@ts-ignore
            socket.io.on("close", () => {
                  setConnectionState( ConnectionStatusEnum.close);
            })

            return () => {
                  // any 'socket.on' above need to have a socket.off here to avoid registering multiple listeners when react redraws
                  socket.off('connect');
                  socket.off('disconnect');
                  socket.off('pong');
            };
      });

      const stationColumns: IStationColumn[] = [
            { title: "Cutting", id: "cutting", frames: [], frameTypes: [FrameTypesEnum.alloy, FrameTypesEnum.premiere] },
            { title: "Boring", id: "boring", frames: [], frameTypes: [FrameTypesEnum.premiere] },
            { title: "Coloring", id: "coloring", frames: [], frameTypes: [FrameTypesEnum.premiere] },
            { title: "Pins & Magnets", id: "pins-magnets", frames: [], frameTypes: [FrameTypesEnum.premiere] },
            { title: "QC", id: "quality-control", frames: [], frameTypes: [FrameTypesEnum.premiere] },
            { title: "Boxing", id: "boxing", frames: [], frameTypes: [FrameTypesEnum.alloy, FrameTypesEnum.premiere] },
            { title: "Tickets to Ship", id: "shipping", frames: [], frameTypes: [FrameTypesEnum.premiere] },

            { title: "Milling", id: "milling", frames: [], frameTypes: [FrameTypesEnum.alloy] },
            { title: "Final Prep", id: "finalprep", frames: [], frameTypes: [FrameTypesEnum.alloy] },

            { title: "Completed Today", id: "completed-today", frames: [], frameTypes: [FrameTypesEnum.premiere] },

      ];

      const getColumnStore = (curStation: number) => {
            let column = null;
            switch (curStation) {
                  case StationsEnum.cutting:
                        column = cutting
                        break;
                  case StationsEnum.boring:
                        column = boring
                        break;
                  case StationsEnum.coloring:
                        column = coloring
                        break;
                  case StationsEnum.pinsAndMagnets:
                        column = pinsAndMagnets
                        break;
                  case StationsEnum.qualityControl:
                        column = qc
                        break;
                  case StationsEnum.boxing:
                        column = boxing
                        break;
                  case StationsEnum.shipping:
                        column = shipping
                        break;
                  //                        case StationsEnum.premiereAwaitingShipping:
                  //                              column = awaitingShipsetShipping
                  //                              break;       
                  case StationsEnum.alloyCutting:
                        column = cutting
                        break;

                  case StationsEnum.milling:
                        column = milling
                        break;
                  case StationsEnum.finalPrep:
                        column = finalPrep
                        break;
                  case StationsEnum.boxingAlloy:
                        column = boxing
                        break;
                  case StationsEnum.premiereInventory:
                        column = premiereFrameCompleted
                        break;        
                  case StationsEnum.alloyInventory:
                        column = alloyFrameCompleted
                        break;                
                  default:
                  // code block
            }

            return column;
      }

      const getColumnHook = (curStation: number) => {
            let setColumnHook = null;
            switch (curStation) {
                  case StationsEnum.cutting:
                        setColumnHook = setCutting
                        break;
                  case StationsEnum.boring:
                        setColumnHook = setBoring
                        break;
                  case StationsEnum.coloring:
                        setColumnHook = setColoring
                        break;
                  case StationsEnum.pinsAndMagnets:
                        setColumnHook = setPingsAndMagnets
                        break;
                  case StationsEnum.qualityControl:
                        setColumnHook = setQC
                        break;
                  case StationsEnum.boxing:
                        setColumnHook = setBoxing
                        break;
                  case StationsEnum.shipping:
                        setColumnHook = setShipping
                        break;
                  case StationsEnum.premiereAwaitingShipping:
                        setColumnHook = setShipping
                        break;
                  //alloy
                  case StationsEnum.alloyCutting:
                        setColumnHook = setCutting
                        break;
                  case StationsEnum.milling:
                        setColumnHook = setMilling
                        break;
                  case StationsEnum.finalPrep:
                        setColumnHook = setFinalPrep
                        break;
                  case StationsEnum.boxingAlloy:
                        setColumnHook = setBoxing
                        break;                        
                  case StationsEnum.premiereInventory:
                        setColumnHook = setPremiereFrameCompleted
                        break;         
                  case StationsEnum.alloyInventory:
                        setColumnHook = setAlloyFrameCompleted
                        break;                                                         
                  default:
                  // code block
            }

            return setColumnHook;
      }

      const getColumnTotalsForStation = ( stationId: number ) => {

            let setColumnHook = null;
            switch (stationId) {
                  case StationsEnum.cutting:
                        setColumnHook = setCuttingTotals
                        break;
                  case StationsEnum.boring:
                        setColumnHook = setBoringTotals
                        break;
                  case StationsEnum.coloring:
                        setColumnHook = setColoringTotals
                        break;
                  case StationsEnum.pinsAndMagnets:
                        setColumnHook = setPingsAndMagnetsTotals
                        break;
                  case StationsEnum.qualityControl:
                        setColumnHook = setQCTotals
                        break;
                  case StationsEnum.boxing:
                        setColumnHook = setBoxingTotals
                        break;
                  case StationsEnum.shipping:
                        setColumnHook = setShippingTotals
                        break;
                  case StationsEnum.premiereAwaitingShipping:
                        setColumnHook = setShippingTotals
                        break;
                //alloy
                  case StationsEnum.alloyCutting:
                        setColumnHook = setCuttingTotals
                        break;                        
                  case StationsEnum.milling:
                        setColumnHook = setMillingTotals
                        break;
                  case StationsEnum.finalPrep:
                        setColumnHook = setFinalPrepTotals
                        break;
                  case StationsEnum.boxingAlloy:
                        setColumnHook = setBoxingTotals
                        break;                        
                  case StationsEnum.premiereInventory:
                        setColumnHook = setPremiereFrameCompletedTotals
                        break;         
                  case StationsEnum.alloyInventory:
                        setColumnHook = setAlloyFrameCompletedTotals
                        break;                                                         
                  default:
                  // code block
            }

            return setColumnHook;
      }

      /* 
            looks through all hook stores to find the frame by serial number and returns a reference to the store if found
            providing the curStation optimizes the lookup if provided (most frames will/should be in the station they are scanned)
       
      */
      const findStationForFrame = (frameSerialId: string, curStation?: number ): { stationId: number, store: FIP[], setHook: React.Dispatch<React.SetStateAction<FIP[]>> } | null => {
            // the frame should be in the curStation where it was scanned but it maybe out of order

            let foundAtCurrentStation: FIP | undefined;
            if ( curStation ) {
                  // look in the current station first since it most likely is there (fast case)
                  foundAtCurrentStation = allStores.find(f => f.stationId === curStation)?.store.find(f => f.serialId === frameSerialId);
            }

            let found = null;
            if (foundAtCurrentStation) {
                  return allStores.find(f => f.stationId === curStation)!;
            } else {
                  if ( curStation ) {
                        found = allStores.filter(store => ( store.stationId !== curStation)).find( store => store.store.find(f => f.serialId === frameSerialId) !== undefined)
                  } else {
                        found = allStores.find( store => store.store.find(f => f.serialId === frameSerialId) !== undefined)
                  }
            }
            if ( found ) {
                  return found;
            } else {
                  return null;
            }
      }

      useEffect(() => {
            // use the time on the web page, not 100% accurate if viewed from another country but close enough for now
            let midnightToday = new Date( new Date(new Date().setHours(23, 59, 59, 999)).getTime() + 2000 );

            let msToMidnight = midnightToday.getTime() - Date.now();
            const timer = setTimeout(() => {
                  console.log('This will run after 1 second!')
            }, msToMidnight);
            return () => clearTimeout(timer);

      }, []);


      useEffect(() => {
           setPremiereFrameCompleted( [])
           setAlloyFrameCompleted([])
      }, [curDate]);

      useEffect(() => {

            // used for a completed frame, including when it is rejected or voided 
            // when ending work, the frame should be in the curStation already (moved there from the start work).. however if a start work event
            // was lost it could still be elsehwere and need removing/adding 
            // - to animate, want the frame 'just scanned' to show 'COMPLETED' then go off the screen.
            // - the frame should also appear in its new column - perhaps with a 'processed today' or some other state, although sorting should pop it
            const endWorkOnFrame = (curStation: number, nextStation: number, frameSerialId: string, messageType: FIPMessageTypes, userName?: string, nextStationId?: number) => {

                  // most likely the frame is in the current station's column so try that first to be fast
                  let setCurColumnHook = getColumnHook(curStation);
                  let frameToFind: FIP | undefined;

                  if (setCurColumnHook) {

                        // remove the frame from whatever station column it is currently in 
                        // and move it to its destination column (if there is one - it could be completed)
                        setCurColumnHook( frames => {
                              // remove the frame from the column
                              frameToFind = frames.find( f => f.serialId === frameSerialId );                           
                              let otherFrames = frames.filter(frame => frame.serialId !== frameSerialId)

                              if ( frameToFind ) {
                                    frameToFind!.workStartTime = undefined;
                                    // create a copy of the frame (with original id) and move it to the destination column
                                    // after, replace the 'start of work' frame with a temp copy so it can be animated out
                                    let destinationColumnHook = getColumnHook(nextStation);

                                    if ( destinationColumnHook ) {
                                          /// create original in the destintation column
                                          // create a copy of the original which will then be moved to the reject to column
                                          let copyFrame = JSON.parse(JSON.stringify(frameToFind!));
                                          copyFrame.id =  frameToFind?.id.replace( '.animated', ''); // this code is run *after* the code outside the hook below
                                          copyFrame.workState = messageType === FIPMessageTypes.FRAME_REJECTED ? FIPMessageTypes.FRAME_REJECTED_HERE.valueOf() : FIPMessageTypes.ADDED_FROM_OTHER_STATION.valueOf();
                                          copyFrame.userName = userName;
                                          copyFrame.curStationId = nextStation;

                                          destinationColumnHook( destinationColumnFrames => {
                                                let others = destinationColumnFrames.filter( f=>f.serialId !== frameSerialId)
                                                return [...others, copyFrame ].sort(sortFrameColumn);
                                          })
                                    }

                                    // the frame in the current column, give it a new id so that it is distinct and the animation can remove it.
                                    // it is either rejected OR ended
                                    //this code will likely be run before the movetoColumnHook above
                                    frameToFind!.id = frameToFind!.id + ".animated"; // change id so it does not collide with the frame inserted in the destination column  - this will be remove anyhow after animation
                                    frameToFind!.workState =  messageType.valueOf(); // completed
                                    frameToFind!.nextStationId = nextStationId;
                                    frameToFind!.userName = userName;
                                    return [...otherFrames, frameToFind!].sort(sortFrameColumn);
                              } else {
                                    // frame is not in the station column it was scanned in
                                    // so find the frame in the wrong column and then remove it
                                    // add it to the correct column using a temp ID so it doesn't clash.  Animate it as completed.
                                    // and add the frame to the 'next column' (which will be the rejected to or next in the process )

                                    // remove from 'wrong column'
                                    let frameFoundInWrongColumn = findStationForFrame(frameSerialId, curStation);
                                    let frameFound: FIP | undefined;
                                    if ( frameFoundInWrongColumn ) {
                                         
                                          // remove from other column where it shouldn't be
                                          frameFoundInWrongColumn.setHook( frames => {
                                                 
                                                let otherFrames = frames.filter(frame => frame.serialId !== frameSerialId);
                                                frameFound = frames.find( f => f.serialId === frameSerialId );     

                                                if ( frameFound ) {
                                                      frameFound!.workStartTime = undefined;

                                                      // add to the correct column where it was just finished so it can be animated as moving to the next column
                                                      let setCurColumnHook = getColumnHook(curStation);
                                                      if ( setCurColumnHook) {
                                                            let copyFrame = JSON.parse(JSON.stringify(frameFound!));
                                                           // copyFrame.workState = FIPMessageTypes.FRAME_REJECTED_HERE;
                                                            copyFrame!.id = frameFound!.id + ".temp_moved"; // make it different
                                                            copyFrame!.workState = FIPMessageTypes.FRAME_END;
                                                            copyFrame!.userName = userName;
                                                            copyFrame!.nextStationId = nextStationId
                                                            setCurColumnHook( frames => {
                                                                  return [...frames, frameFound!].sort(sortFrameColumn);;
                                                            })
                                                      }

                                                      // add the frame to its destination column
                                                      let setNextColumnHook = getColumnHook(nextStation);
                                                      if ( setNextColumnHook ) {
                                                            setNextColumnHook( frames => {
                                                                  frameFound!.workState = messageType === FIPMessageTypes.FRAME_REJECTED ? FIPMessageTypes.FRAME_REJECTED.valueOf() : undefined;
                                                                  frameFound!.userName = undefined;
                                                                  return [...frames, frameFound!].sort(sortFrameColumn);
                                                            })
                                                      }
      
                                                }
                                                // remove from the wrong column it was found in
                                                return [...otherFrames];
                                          })

                                          
                                    }

                                    if ( frameFound ) {
                                          let copyFrame = JSON.parse(JSON.stringify(frameFound!));
                                          // copyFrame.workState = FIPMessageTypes.FRAME_REJECTED_HERE;
                                           copyFrame!.id = frameFound!.id + ".animated"; // make it different
                                           copyFrame!.workState = FIPMessageTypes.FRAME_END;
                                          return [...otherFrames, frameFound!].sort(sortFrameColumn);
                                    } else {
                                          return [...otherFrames];
                                    }
                              }
                         
                        })

                  }
            }
            
            const startWorkOnFrame = (curStation: number, frameSerialId: string, messageType: FIPMessageTypes, userName?: string) => {
                  // work is starting on this frame at this station
                  // need to take into account 
                  //  1) the frame may not be in the current station
                  //  2) the work on the frame may be canceled
                  //  3) 1 and 2 together means that a frame in the wrong station may get moved to the current station
                  //    to show work started, but if the work is canceled, it should go back to the original station (we aren't updating until work is finished)
                  //    so ... leave the frame in the original station _AND_ display it in the current station, then if canceled remove from current station only?

                  let frameFoundInColumn = findStationForFrame(frameSerialId, curStation);
               
                  // find the frame - doesn't matter if the data is stale, just want a copy of the frame
              //    let frameFound = frameFoundInColumn!.store.find(f => f.serialId === frameSerialId);
                  // if the frame is not found in a different column, want to remove it from there
                  // before adding it to the correct column
                  // but want to animate a copy of the original first
                  let frameFoundInCorrectColumn = frameFoundInColumn === null ? false : frameFoundInColumn!.stationId.valueOf() === curStation;

                  //if the frame is not in the curent column, it first needs to be removed from the 'other colummn' then added to the current column
                  let frameToFind: FIP | undefined  = frameFoundInColumn?.store.find( f=> f.serialId === frameSerialId);
            
            
                  //if ( !frameToFind ) {
                    //    return;
                 // }
                  if ( frameToFind ) {
                        frameToFind = JSON.parse( JSON.stringify( frameToFind));
                        frameToFind!.returnToStationIfCanceled = undefined;
                  } else {
                        let i = 0;
                        i =i +1;
                        console.error( `FRAME NOT FOUND ON SCREEN ${frameSerialId} station: ${curStation} message: ${messageType} user ${userName}`);
                        return;
                  }

                 if ( !frameFoundInCorrectColumn && frameFoundInColumn) {
                    // frame is not in the current station so remove it from whatever station it was found in
                        frameFoundInColumn.setHook(frames => {
                              frameToFind!.returnToStationIfCanceled = frameFoundInColumn!.stationId;
//                              frameToFind = frames.find(f => f.serialId === frameSerialId);
                              // frame was not found in the correct column so remove it
                              let otherFrames = frames.filter(frame => frame.serialId !== frameSerialId);
                              return [...otherFrames];
                        })
                  }

                  // add the frame to the correct column (the station being worked at
                  // if its not already there
                  let setCurColumnHook = getColumnHook(curStation);
                     // the frame was found in the correct column
                  if ( setCurColumnHook) {

                        // need to add the frame (from the other column)  _or_ move it if its already in the correct column
                        setCurColumnHook( originalColumnFrames => {
                              if ( !frameFoundInCorrectColumn  ) {
                                    //add the frame found in another column to the current station's column
                                    frameToFind!.workState = messageType.valueOf();
                                    frameToFind!.curStationId = curStation;
                                    frameToFind!.workStartTime = Date()
                                    frameToFind!.userName = userName;
                                    return [...originalColumnFrames, frameToFind!].sort(sortFrameColumn);
                              } else {
                                    //frame was already in the correct column so just mark it as in progress
                                    const frameInCurrentColumn = originalColumnFrames.find(f => f.serialId === frameSerialId);
                                    const otherFrames = originalColumnFrames.filter(frame => frame.serialId !== frameSerialId);               
                                    frameInCurrentColumn!.workState = messageType.valueOf();
                                    frameInCurrentColumn!.workStartTime = Date();
                                    frameInCurrentColumn!.userName = userName;
                                    return [...otherFrames, frameInCurrentColumn!].sort(sortFrameColumn);      
                              }
                        })
                  } else  {
                        console.error ( `FRAME NOT FOUND IN START WORK ON FRAME ${frameSerialId}`)
                        // frame not found -- ignore it
                  }
            }

            const cancelStartOfWorkOnFrame = ( curStationId: number, frameSerialId: string ) => {

               let setCurColumnHook = getColumnHook(curStationId);
                  // the frame was found in the correct column - should be since it would have been moved on start scan
                  if ( setCurColumnHook) {
                        setCurColumnHook( originalColumnFrames => {
                              let frameToCancel = originalColumnFrames.find( f=> f.serialId === frameSerialId);
                              let otherFrames = originalColumnFrames.filter( f => f.serialId !== frameSerialId );

                              if ( frameToCancel  ) {
                                    frameToCancel!.workState = FIPMessageTypes.FRAME_START_CANCELED.valueOf();
                                    frameToCancel.userName = undefined; 
                                    return [...otherFrames, frameToCancel!].sort(sortFrameColumn);
                              } else {
                                    return [...originalColumnFrames].sort(sortFrameColumn);
                              }
                        })
                  }
            }

            const voidFrame = ( curStationId: number, frameSerialId: string, userName?: string ) => {
                  // When voiding a frame, the frame may or may not be at the app's curstation.  So look for the frame in all columns.
                  let frameFoundInColumn = findStationForFrame(frameSerialId, curStationId);
                  if ( frameFoundInColumn ) {
                        let voidedFrame: FIP | undefined = undefined;
                  
                  
                        // frame was found but not in the station the user is currently working in,
                        // so move it to the current station. Upon void in the server, the frame will already be moved there to reflect where it was voided from.
                        frameFoundInColumn.setHook( allFrames  => {
                              voidedFrame = allFrames.find( f=> f.serialId === frameSerialId );
                              let otherFrames = allFrames.filter( f => f.serialId !== frameSerialId);  

                              voidedFrame!.isVoided = true;
                              voidedFrame!.workState = FIPMessageTypes.FRAME_VOIDED;
                              voidedFrame!.userName = userName;

                              // hack the current station into this var so the app can remove it from the column after animation
                             // voidedFrame!.returnToStationIfCanceled = frameFoundInColumn?.stationId;
                              // On the screen here, it may be in a different column at the start of the void and will be moved to the current column (of the app) and in the db (on void)
                              // (unless it is voided from FIP in the app) before animating the void.
                              
                              // In the with the exception where the does not make sense 
                              // like voiding from the FIP screen in the app. 
                              voidedFrame!.curStationId = curStationId;

                              if ( frameFoundInColumn!.stationId  !== curStationId ) {
                                    // move to current column
                                    getColumnHook( curStationId )!( frames => {  
                                           let otherFrames = frames.filter( f => f.serialId !== frameSerialId);  
                                          return [...otherFrames, voidedFrame!].sort(sortFrameColumn); 
                                    })
                                    // remove from the incorrect column
                                    return [...otherFrames].sort(sortFrameColumn); ;
                              } else {
                                    // voided frame was in the correct column already so add it now marked as voided
                                    return [...otherFrames, voidedFrame!].sort(sortFrameColumn); ;
                              }
                        })
                  }

            }

            const unvoidFrame = ( curStationId: number, frameSerialId: string, fip: FIP, userName?: string ) => {
                  //unvoiding will move the frame to the current column
                  // in theory the unvoided frame should not be already on the screen but check for that case just in case.
                  let frameFoundInColumn = findStationForFrame(frameSerialId, curStationId);
                  if ( frameFoundInColumn ) {
                   
                        frameFoundInColumn.setHook( frames => {
                              let voidedFrame = frames.find( f=> f.serialId === frameSerialId );
                              let otherFrames = frames.filter( f => f.serialId !== frameSerialId);
                                                            
                              voidedFrame!.isVoided = false;
                              voidedFrame!.workState = FIPMessageTypes.FRAME_UNVOIDED;
                              voidedFrame!.userName = userName;

                              if ( frameFoundInColumn?.stationId !== curStationId ) {
                                    // add the voided frame to the current station
                                    getColumnHook( curStationId )!( frames => {      
                                          return [...otherFrames, voidedFrame!].sort(sortFrameColumn); 
                                    })
                                    // remove from the incorrect column
                                    return [...otherFrames].sort(sortFrameColumn); ;
                              } else {
                                    // unvoided frame was int he correct column already so just re-add it marked as unvoided
                                    return [...otherFrames, voidedFrame!].sort(sortFrameColumn); 
                              }
                        })
                  } else {
                          // unvoided frame is not already on the screen, so add it
                          // fip.curStationId should be the station it is actually at before the void was initiated
                          let setCurColumnHook = getColumnHook( curStationId );
                          if ( setCurColumnHook ) {
                              setCurColumnHook( frames => {
                                    let unvoidedFrame = fip;
                                    unvoidedFrame.isVoided = false;
                                    unvoidedFrame.workState = FIPMessageTypes.FRAME_UNVOIDED;
                                    unvoidedFrame.userName = userName;
                                    unvoidedFrame.curStationId = curStationId;
                                    return [...frames, unvoidedFrame ].sort(sortFrameColumn); ;
                              })
                        }
                  }
            }
            const addNewFrames = ( stationId: number, fips: FIP[], userName?: string  ) => {
                  let setCurColumnHook = getColumnHook(stationId);
                  // the frame was found in the correct column
                  if ( setCurColumnHook) {

                        // need to add the frame (from the other column)  _or_ move it if its already in the correct column
                        setCurColumnHook( originalColumnFrames => {

                                    // make sure the new frames aren't added twice
                                    let otherFrames =  originalColumnFrames.filter( f=> !fips.map( fip=> fip.serialId ).includes( f.serialId) );
                                    fips.forEach( f => {
                                          f!.workState = FIPMessageTypes.FRAME_CREATED.valueOf();
                                          f!.workStartTime = Date()
                                          f!.userName = userName;
                                    })
                                    
                                    return [...otherFrames, ...fips!].sort(sortFrameColumn);
                                
                        })
                  }

            }
            const shipFrame = ( curStationId: number, frameSerialId: string ) => {
                  // When voiding a frame, the frame may or may not be at the app's curstation.  So look for the frame in all columns.
                  let frameFoundInColumn = findStationForFrame(frameSerialId, curStationId);
                  if ( frameFoundInColumn ) {
                        let shippedFrame: FIP | undefined = undefined;
                  
                  
                        // frame was found but not in the station the user is currently working in,
                        // so move it to the current station. Upon void in the server, the frame will already be moved there to reflect where it was voided from.
                        frameFoundInColumn.setHook( allFrames  => {
                              shippedFrame = allFrames.find( f=> f.serialId === frameSerialId );
                              let otherFrames = allFrames.filter( f => f.serialId !== frameSerialId);  

                              shippedFrame!.isVoided = true;
                              shippedFrame!.workState = FIPMessageTypes.FRAME_VOIDED;
                        //      shippedFrame!.userName = userName;

                              // hack the current station into this var so the app can remove it from the column after animation
                             // voidedFrame!.returnToStationIfCanceled = frameFoundInColumn?.stationId;
                              // On the screen here, it may be in a different column at the start of the void and will be moved to the current column (of the app) and in the db (on void)
                              // (unless it is voided from FIP in the app) before animating the void.
                              
                              // In the with the exception where the does not make sense 
                              // like voiding from the FIP screen in the app. 
                              shippedFrame!.curStationId = curStationId;

                              if ( frameFoundInColumn!.stationId  !== curStationId ) {
                                   // remove
                                    getColumnHook( curStationId )!( frames => {  
                                           let otherFrames = frames.filter( f => f.serialId !== frameSerialId);  
                                          return [...otherFrames!].sort(sortFrameColumn); 
                                    })
                                    // remove from the incorrect column
                                    return [...otherFrames].sort(sortFrameColumn); ;
                              } else {
                                    // voided frame was in the correct column already so add it now marked as voided
                                    return [...otherFrames].sort(sortFrameColumn); ;
                              }
                        })
                  }

            }
            const addBatchItem = (msg: CuttingStationBatchItemMessage) => {

                  setActiveBatches(prevBatches => {
                        let batches = prevBatches.filter(batch => batch.batchId !== msg.batchId)
                        let modifiedBatch = prevBatches.find(batch => batch.batchId === msg.batchId);

                        if (modifiedBatch) {
                              let item = modifiedBatch.batchItems.find(items => items.productId === msg.batchItem!.productId)
                              if (item) {
                                    item.quantity = msg.batchItem!.quantity;
                              } else {
                                    // new item
                                    let newBatchItem = {} as BatchItem;
                                    newBatchItem.productId = msg.batchItem!.productId;
                                    newBatchItem.profile = msg.batchItem?.profile ?? "profile?"
                                    newBatchItem.quantity = msg.batchItem?.quantity ?? -99;
                                    newBatchItem.size = msg.batchItem!.size ?? "size?";
                                    newBatchItem.style = msg.batchItem?.style ?? "style?";
                                    newBatchItem.year = msg.batchItem?.year ?? -2000;
                                    modifiedBatch.batchItems.push(newBatchItem)

                              }

                              batches.push(modifiedBatch)
                              return batches
                        } else {
                              return [...prevBatches]
                        }
                  })
            }


            socket.on( SOCKET_CHANNELS.STATION.valueOf(), (msg: StationMessage) => {
             
                  if (msg.messageType === StationMessageTypes.STATION_START) {
                        let newUser = {} as StationActivity;
                        newUser.stationId = msg.curStation;
                        newUser.userName = msg.userName;
                        newUser.userId = msg.userId;
                        newUser.activity = StationMessageTypes.STATION_START;       
                        const now = new Date();
                        newUser.startTime = now.getHours() + ":" + now.getMinutes() + ":" + now.getSeconds();
                
                        setStationActivity( prevActivity => {
                              let activeUsers = prevActivity.filter( prevUsers => prevUsers.userId !== msg.userId)
                              return [...activeUsers, newUser];
                        })
                  } else if (msg.messageType === StationMessageTypes.STATION_PAUSE ){
                        // on a pause most likely the user is at the same station but if the start event was lost, perhaps they are elsehwere.
                        setStationActivity( prevActivity => {
                     
                              let curUser = prevActivity.find( prevUsers => prevUsers.userId === msg.userId);
                              const now = new Date();
                              if ( curUser ) {
                                    curUser.activity = StationMessageTypes.STATION_PAUSE;        
                                    curUser.startTime = now.getHours() + ":" + now.getMinutes() + ":" + now.getSeconds();                                   
                                    let activeUsers = stationActivity.filter( prevUsers => prevUsers.userId !== msg.userId)
                              
                                    return [...activeUsers, curUser];
                              } else {
                                    let missingUser = {} as StationActivity;
                                    missingUser.stationId = msg.curStation;
                                    missingUser.userName = msg.userName;
                                    missingUser.userId = msg.userId;
                                    missingUser.activity = StationMessageTypes.STATION_START;       
                                    const now = new Date();
                                    missingUser.startTime = now.getHours() + ":" + now.getMinutes() + ":" + now.getSeconds();
                                    let activeUsers = stationActivity.filter( prevUsers => prevUsers.userId !== msg.userId)
                              
                                    return [...activeUsers, missingUser];
                              }
                        })
                  }
            })
///////////////////
            socket.on(SOCKET_CHANNELS.INVENTORY_PREMIERE.valueOf(), (message) => {
                 
                  handlerInventory( message );

            });
///////////////////
            async function reloadAppWithLastestVersion() {
                 // invalidate the cache so the browser reloads the newest version of the app
                 // not positive this is doing anything useful,  added meta tags to not cache the app
                  if (caches) {
                        const names = await caches.keys();
                        await Promise.all(names.map(name => caches.delete(name)));

                   //     caches.open("v1").then((cache) => {
                     //         cache.delete( window. ).then((response) => {
                     //               let i = 0;
                     //               i = i + 1;
                    //          });
                  //      });

                  }
                  

                  window.location.reload();
            }
///////////////////
            socket.on(SOCKET_CHANNELS.MANUAL_RELOAD.valueOf(), (message) =>  {

                  reloadAppWithLastestVersion()

            });
///////////////////
            function handlerInventory( message: InventoryUpdateMessage ){
                  if ( inInventoryMode === false ){
                        setInInventoryMode( true );
                  }
                  if ( message.messageType === InventoryMessageTypes.ADD_BATCH_ITEM ) {

                        let frameFoundInColumn = findStationForFrame( message.frameSerialId, message.stationId );
                        
                        if (frameFoundInColumn ) {
                              frameFoundInColumn!.setHook( frames => {
                                    let otherFrames = frames.filter(frame => frame.serialId !== message.frameSerialId);
                                    let frameFound = frames.find( f => f.serialId === message.frameSerialId );   

                                    frameFound!.scannedInInventory = true;
                                    frameFound!.scannedInCorrectColumn = message.stationId === frameFoundInColumn!.stationId;
                                    return [...otherFrames, frameFound!];
                              })
                        }
                  } else if ( message.messageType === InventoryMessageTypes.DELETE_BATCH_ITEM ) {


                  } else if ( message.messageType === InventoryMessageTypes.FINISHED_INVENTORY ) {
                        for ( let station of allStores ) {
                              station.setHook(  frames => {
                                    let unhideFrames = frames.map( f => {
                                          f.scannedInInventory = false;
                                          f.scannedInCorrectColumn = undefined;
                                          return f;
                                    })
                                    return [...unhideFrames];
                              });
                         }

                          setInInventoryMode( false );

                  } else if ( message.messageType === InventoryMessageTypes.RESET_INVENTORY ) {
                        for ( let station of allStores ) {
                              station.setHook(  frames => {
                                    let unhideFrames = frames.map( f => {
                                          f.scannedInInventory = false;
                                          f.scannedInCorrectColumn = undefined;
                                          return f;
                                    })
                                    return [...unhideFrames];
                              });
                         }
                  }
            }

///////////////////
            socket.on(SOCKET_CHANNELS.BOXING_PREP.valueOf(), (message) => {
                  let msg = message as BoxingPrepMessage;

                  if ( frameType === FrameTypesEnum.premiere ) {
                        if ( msg.messageType === BoxingPrepMessageTypes.BOX_MADE ) {
                              setBoxingPrepInventoryTotal( prevValue => {
                                    return prevValue + 1;
                              })
                        } else if (msg.messageType === BoxingPrepMessageTypes.BOX_USED ) {
                              setBoxingPrepInventoryTotal( prevValue => {
                                    return prevValue - 1;
                              })
                        } else {
                              console.log( `ERRORL UNRECOGNIZED boxing prep message ${JSON.stringify( msg ) }`);
                        }
                  }
            });
 ///////////////////

            socket.on(SOCKET_CHANNELS.CUTTING_PREMIERE.valueOf(), (message) => {
                  if ( frameType === FrameTypesEnum.premiere ) {
                        handlerCuttingMessages( message, setActiveBatches)
                  }
            });

            socket.on(SOCKET_CHANNELS.CUTTING_ALLOY.valueOf(), (message) => {
                  if ( frameType === FrameTypesEnum.alloy ) {
                        handlerCuttingMessages( message, setActiveBatches)
                  }
            });

            function handlerCuttingMessages( message: MessageBase, setActiveBatches: React.Dispatch<React.SetStateAction<ActiveBatch[]>> ) {
                  let msg = message as MessageBase;

                  if (msg.messageType === MessageTypes.BATCH_CRUD) {
                        let m = msg as CuttingStationBatchMessage;
                        //TODO need to handle recuts??
                        if (m.batchState === BatchState.CREATING) {
                              // at creating the batch has an id and a user but no other info is yet input
                              let newBatch = {} as ActiveBatch;
                              newBatch.batchId = m.batchId;
                              newBatch.cutBy = "TODO"
                              newBatch.frameStyle = m.batchDetails?.frameStyle ?? ""
                              newBatch.batchItems = [];
                              newBatch.image = m.batchDetails?.image ?? "";
                              newBatch.isRecutBatch = m.batchDetails?.isRecut ?? false;
                              newBatch.recutFrameSerialId = m.batchDetails?.recutFrameSerialId;
                              newBatch.recutFrameSize = m.batchDetails?.recutFrameSize;
                              newBatch.recutFrameYear = m.batchDetails?.recutFrameYear;

                              setActiveBatches(prevBatches => {
                                    let batches = prevBatches.filter(batch => batch.batchId !== m.batchId);
                                    return [...batches, newBatch];
                              })

                        } else if (m.batchState === BatchState.STARTED) {
                              //batch went from created to -> started so it now has a style, image 
                              // decided not to show the 'created' state since it has limited useful info and rather add it here
                              // the started state can now therefore be either a creation or an update (ie. if use changes frame style)
                              // this however never 'adds batch items' so no need to worry about that
                              setActiveBatches(prevBatches => {
                                    let batches = prevBatches.filter(batch => batch.batchId !== m.batchId)
                                    let existingBatch = prevBatches.find(batch => batch.batchId === m.batchId)

                                    if (existingBatch) {
                                          existingBatch.frameStyle = m.batchDetails?.frameStyle ?? "style?"
                                          existingBatch.image = m.batchDetails?.image ?? "image?"
                                          return [...batches, existingBatch];
                                    } else {
                                          let newBatch = {} as ActiveBatch;

                                          newBatch.batchId = m.batchId;
                                          newBatch.cutBy = m.batchDetails?.createdBy ?? "user?"
                                          newBatch.timeStarted = m.batchDetails?.createdDateTime ?? "time?"
                                          newBatch.frameStyle = m.batchDetails?.frameStyle ?? "style?"
                                          newBatch.batchItems = [];
                                          newBatch.image = m.batchDetails?.image ?? "image?"

                                          return [...batches, newBatch]
                                    }
                              });

                        } else if (m.batchState === BatchState.ENDED) {

                              setActiveBatches(prevBatches => {
                                    let batches = activeBatches.filter(batch => batch.batchId !== m.batchId)
                                    return batches;
                              })

                        } else if (m.batchState === BatchState.CANCELED) {
                              setActiveBatches(prevBatches => {
                                    let batches = activeBatches.filter(batch => batch.batchId !== m.batchId)
                                    return batches;
                              })
                        }

                  } else if (msg.messageType === MessageTypes.ADD_BATCH_ITEM) {
                        addBatchItem(msg as CuttingStationBatchItemMessage);
                  } else if (msg.messageType === MessageTypes.DELETE_BATCH_ITEM) {
                        setActiveBatches(prevBatches => {
                              let m = msg as CuttingStationBatchItemMessage;
                              let batches = prevBatches.filter(batch => batch.batchId !== m.batchId)
                              let modifiedBatch = prevBatches.find(batch => batch.batchId === m.batchId);
                              if (modifiedBatch) {
                                    if (m.batchItem && m.batchItem!.quantity === 0) {
                                          // no items left of this year/size so remove entry
                                          const newItems = modifiedBatch.batchItems.filter(i => i.productId !== m.batchItem!.productId);
                                          modifiedBatch.batchItems = newItems;
                                          return [...batches, modifiedBatch];
                                    } else {
                                          // set the quantity of an existing entry
                                          let item = modifiedBatch.batchItems.find(items => items.productId === m.batchItem!.productId)
                                          if (item) {
                                                item.quantity = m.batchItem!.quantity;
                                          }
                                          batches.push(modifiedBatch)
                                          return batches
                                    }

                              } else {
                                    return [...prevBatches]
                              }
                        })
                  } else if ( msg.messageType === MessageTypes.CUTTING_TICKET ) {

                        let m = msg as CuttingTicketMessage;
                        if ( m.priority === "1") { //low
                              setCuttingLowPriorityTicketsTotal( quantity => {
                                    return quantity + m.quantity;
                              });
                        } else if ( m.priority === "2") { //high
                              setCuttingHighPriorityTicketsTotal( quantity => {
                                    return quantity + m.quantity;
                              });
                        }
                  }
            }

            socket.on(SOCKET_CHANNELS.FIP.valueOf(), (message) => {

                  console.log('received' + message)
                  // move frames from one station to another as events are recorded
                  let m = message as FIPMessage;
                  
                  // this will process for both premiere and alloy but only one will be shown depending on the initial url so filter out alloy if shown premiere and vice versa
                  if ( m.messageType === FIPMessageTypes.FRAME_CREATED ) {     
                        if ( m.batchFIP && m.batchFIP.length > 0 ) {
                              if ( m.batchFIP[0].serialId.charAt(1) === 'P' && frameType !== 'premiere' ) {
                                    return;  // for alloy screen not premiere
                              } else if ( m.batchFIP[0].serialId.charAt(1) === 'A' && frameType !== 'alloy' ) {
                                    return;  // for alloy screen not premiere
                              }
                        }
                  } else if  ( frameType === 'premiere' && m.frameSerialId.charAt(1) !== 'P') {
                        return; // alloy frame but showing premiere
                  } else if  ( frameType === 'alloy' && m.frameSerialId.charAt(1) !== 'A') {
                        return; //premiere frame but showing alloy
                  }

                  // if a frame is started, add it in the appropriate column
                  // if it is finished, remove its current column and move it to its destination
                  // if the voided - remove it from display
                  // if it is unvoided - add it back to the display in the appropriate column
                  // if it is rejected - need to show rejection message at the station it is at ... and once that animation finishes... move it to its final destination
                  if (m.messageType === FIPMessageTypes.FRAME_END || m.messageType === FIPMessageTypes.FRAME_REJECTED ) {//|| m.messageType === FIPMessageTypes.FRAME_VOIDED || m.messageType === FIPMessageTypes.FRAME_REJECTED) {

                        // remove the frame (and animate) from the column it is currently in 
                        // note that it is possible it is elsewhere if the process is out of order on the floor
                        
                        // frame_ended is in the current column, so need to check the next station to see which column total needs to be updated
                        if ( m.curStation ) {
                              getColumnTotalsForStation( m.curStation! )! ( totals => {
                                    if ( !totals ) {

                                    }
                                    let totalUpdate = JSON.parse( JSON.stringify(totals) );
                                    if ( m.messageType === FIPMessageTypes.FRAME_END) {
                                          totalUpdate!.completed.total += 1;
                                    } else if ( m.messageType === FIPMessageTypes.FRAME_REJECTED) {
                                          totalUpdate!.rejected += 1;
                                    }
                                    return totalUpdate!;
                              }); 

                              // this could be done in other ways like watching the count in the completed today + tickets column but try it this way
                              // as a double check.  both should give the same result.
                              if ( m.curStation === StationsEnum.boxing && m.messageType===FIPMessageTypes.FRAME_END ) {
                                    setPremiereTally( curValue => {
                                          return curValue + 1;
                                    });
                                    setPremiereFrameCompletedTotals( totals => {
                                          let totalUpdate = JSON.parse( JSON.stringify(totals) );
                                          if ( totalUpdate ) {
                                                totalUpdate!.completed.total  = totalUpdate!.completed.total + 1;
                                          }
                                          return totalUpdate;
                                    });
                              }

                              if ( m.curStation === StationsEnum.boxingAlloy && m.messageType===FIPMessageTypes.FRAME_END ) {
                                    setAlloyTally( curValue => {
                                          return curValue + 1
                                    });

                                    setAlloyFrameCompletedTotals( curValue => {
                                          let totalUpdate = JSON.parse( JSON.stringify(curValue) );
                                          if ( totalUpdate ) {
                                                totalUpdate!.completed.total  = totalUpdate!.completed.total + 1;
                                          }
                                          return totalUpdate
                                    });
                              }
                        }

                        endWorkOnFrame( m.curStation, m.nextStation!, m.frameSerialId, m.messageType, m.userName, m.nextStation );


                  } else if (m.messageType === FIPMessageTypes.FRAME_START ) { //|| m.messageType === FIPMessageTypes.FRAME_UNVOIDED ) {

                        startWorkOnFrame(m.curStation, m.frameSerialId, m.messageType, m.userName );
                  } else if  ( m.messageType === FIPMessageTypes.FRAME_UNVOIDED ) { 
                        unvoidFrame( m.curStation, m.frameSerialId, new FIP( m.fip! ), m.userName);
                  } else if  ( m.messageType === FIPMessageTypes.FRAME_VOIDED ) { 
                        voidFrame( m.curStation, m.frameSerialId, m.userName );
                  } else if ( m.messageType === FIPMessageTypes.FRAME_START_CANCELED) {
                        cancelStartOfWorkOnFrame( m.curStation, m.frameSerialId );
                  } else if ( m.messageType === FIPMessageTypes.FRAME_CREATED) {
                        addNewFrames( m.curStation, m.batchFIP!.map( f => new FIP( f ) )as FIP[], m.userName);
                  } else if ( m.messageType == FIPMessageTypes.FRAME_SHIPPED) {
                        // TODO THIS SHOUD BE ANIMATED
                        shipFrame( m.curStation, m.frameSerialId );
                  }

            });

            socket.on('batch_start', (message) => {
                  console.log('received' + message)
            });

            socket.on('batch_end', (message) => {
                  console.log('received' + message)
            });

            return () => {
                  // any 'socket.on' above need to have a socket.off here to avoid registering multiple listeners when react redraws
                  socket.off('connect');
                  socket.off('disconnect');
                  socket.off('pong');
                  socket.off('batch_end'); 
                  socket.off('batch_end');
                  //https://stackoverflow.com/questions/71807584/socket-io-client-repeats-event-action-multiple-times-from-one-emit
                  socket.off( SOCKET_CHANNELS.FIP.valueOf() );
                  socket.off( SOCKET_CHANNELS.BOXING_PREP.valueOf() );
                  socket.off( SOCKET_CHANNELS.STATION.valueOf() );
                  socket.off( SOCKET_CHANNELS.INVENTORY_PREMIERE.valueOf() );
                  socket.off( SOCKET_CHANNELS.MANUAL_RELOAD.valueOf() );
                  socket.off( SOCKET_CHANNELS.CUTTING_PREMIERE.valueOf() );
                  socket.off( SOCKET_CHANNELS.CUTTING_ALLOY.valueOf() );
            };
      });

      React.useEffect(() => {
            let url = window.location.pathname;
            let frameType = url.replace( '/', '')
            if ( frameType === 'premiere') {
                  setFrameType( FrameTypesEnum.premiere)
            } else if ( frameType === 'alloy') {
                  setFrameType( FrameTypesEnum.alloy)
            } else {
                  return;
            }

            axios.get(`${Config.baseUrl}/api/frames-in-progress/${frameType}`).then((response) => {
                  console.log( Config.baseUrl )

                  if (frameType === FrameTypesEnum.premiere) {
                        setCutting(response.data.filter((i: FIP) =>  i.curStationId === StationsEnum.cutting).map( (f: FIP ) => new FIP( f )).sort(sortFrameColumn));
                        setBoring(response.data.filter((i: FIP) => i.curStationId === StationsEnum.boring).map( (f: FIP ) => new FIP( f )).sort(sortFrameColumn));
                        setColoring(response.data.filter((i: FIP) => i.curStationId === StationsEnum.coloring).map( (f: FIP ) => new FIP( f )).sort(sortFrameColumn));
                        setPingsAndMagnets(response.data.filter((i: FIP) => i.curStationId === StationsEnum.pinsAndMagnets).map( (f: FIP ) => new FIP( f )).sort(sortFrameColumn));
                        setQC(response.data.filter((i: FIP) => i.curStationId === StationsEnum.qualityControl).map( (f: FIP ) => new FIP( f )).sort(sortFrameColumn));
                        setBoxing(response.data.filter((i: FIP) => i.curStationId === StationsEnum.boxing).map( (f: FIP ) => new FIP( f )).sort(sortFrameColumn));
                        setShipping(response.data.filter((i: FIP) => i.curStationId === StationsEnum.premiereAwaitingShipping).map( (f: FIP ) => new FIP( f )).sort(sortFrameColumn));
                        setPremiereFrameCompleted(response.data.filter((i: FIP) => i.curStationId === StationsEnum.premiereInventory).map( (f: FIP ) => new FIP( f )).sort(sortFrameColumn));
                  } else {
                        setCutting(response.data.filter((i: FIP) => i.curStationId === StationsEnum.alloyCutting).map( (f: FIP ) => new FIP( f )).sort(sortFrameColumn));
                        setMilling(response.data.filter((i: FIP) => i.curStationId === StationsEnum.milling).map( (f: FIP ) => new FIP( f )).sort(sortFrameColumn));
                        setFinalPrep(response.data.filter((i: FIP) => i.curStationId === StationsEnum.finalPrep).map( (f: FIP ) => new FIP( f )).sort(sortFrameColumn));
                        setBoxing(response.data.filter((i: FIP) => i.curStationId === StationsEnum.boxingAlloy).map( (f: FIP ) => new FIP( f )).sort(sortFrameColumn));
                        setAlloyFrameCompleted(response.data.filter((i: FIP) => i.curStationId === StationsEnum.alloyInventory).map( (f: FIP ) => new FIP( f )).sort(sortFrameColumn));
                  }

 
                  axios.get( `${Config.baseUrl}/api/frames-in-progress/totals/station/${frameType}`).then((response) => {
                        for ( const row of response.data ) {
                              let setHook =  getColumnTotalsForStation( row.stationId );
                              if ( setHook ) {
                                    setHook ({
                                          completed : {
                                                total: row.completed,
                                                average: 0
                                           },
                                          rejected: row.rejected ?? 0,
                                          voided: row.voided ?? 0
                                    } as IStationColumnTotals )
                              }
                        }
                  });

                  axios.get(`${Config.baseUrl}/api/frames-in-progress/active-batches/${frameType}`).then((response) => {
                        setActiveBatches(prevBatches => response.data.activeBatches);
                  });

                  axios.get(`${Config.baseUrl}/api/frames-in-progress/active-users/${frameType}`).then((response) => {
                        let activeUsers:StationActivity[] = [];
                        for ( let user of response.data.activeUsers ) {

                              if ( user.isTimerRunning === true ) {

                                    let newUser = {} as StationActivity;
                                    newUser.stationId = user.stationId;
                                    newUser.userName = user.userName;
                                    newUser.userId = user.userId;
                                    newUser.activity = StationMessageTypes.STATION_START;       
                                    const now = new Date();
                                    newUser.startTime = user.timeLocal;                                                                
                                    activeUsers.push( newUser );
                              }
                        }
                        setStationActivity(  prevActivity => { 
                             return activeUsers 
                        });
                  });
          
                  axios.get( `${Config.baseUrl}/api/frames-in-progress/totals/completed/` ).then((response) => {
                        setAlloyFrameCompletedTotals( {
                              completed: {
                                    total: response.data.alloyCompleted,
                                    average: 0 // not yet calculated TODO
                              },
                              voided: 0,
                              rejected: 0
                        });
                        setPremiereFrameCompletedTotals(   {
                              completed: {
                                    total: response.data.premiereCompleted,
                                    average: 0 // not yet calculated TODO
                              },
                              voided: 0,
                              rejected: 0
                        });
                        setPremiereTally( response.data.premiereCompleted );
                        setAlloyTally( response.data.alloyCompleted );
                  })

                  axios.get( `${Config.baseUrl}/api/shipstation/tickets/pending-to-cut/${frameType}` ).then((response) => {
                        let lowTotal = response.data.filter( (i : any) => i.shipping_priority_id === "1")
                        let highTotal = response.data.filter( (i : any) => i.shipping_priority_id === "2")

                        setCuttingLowPriorityTicketsTotal( lowTotal && lowTotal.length && lowTotal.length > 0 ?  parseInt(lowTotal[0].total ) : 0  );
                        setCuttingHighPriorityTicketsTotal( highTotal && highTotal.length && highTotal.length > 0 ?  parseInt(highTotal[0].total ) : 0  )
                  })


                  

                  axios.get( `${Config.baseUrl}/api/frames-in-progress/totals/boxing-prep-inventory/`).then( (response ) => {
                        setBoxingPrepInventoryTotal( response.data.boxingPrepInventoryCount);
                  })
                  axios.get( `${Config.baseUrl}/api/frames-in-progress/totals/boxing-prep-inventory/`).then( (response ) => {
                        setBoxingPrepInventoryTotal( response.data.boxingPrepInventoryCount);
                  })
            });
      }, [isConnected]);

      const removeFromCuttingColumn = useCallback(( frameSerialId: string ) => {
            setCutting((f) => [...f.filter( frame=>frame.serialId !== frameSerialId)] )
      }, []);
      const removeFromBoringColumn = useCallback(( frameSerialId: string ) => {
            setBoring((f) => [...f.filter( frame=>frame.serialId !== frameSerialId)] )
      }, []);
      const removeFromColoringColumn = useCallback(( frameSerialId: string ) => {
            setColoring((f) => [...f.filter( frame=>frame.serialId !== frameSerialId)] )
      }, []);   
      const removeFromPinsAndMagnetsColumn = useCallback(( frameSerialId: string ) => {
            setPingsAndMagnets((f) => [...f.filter( frame=>frame.serialId !== frameSerialId)] )
      }, []);  
      const removeFromQCColumn = useCallback(( frameSerialId: string ) => {
            setQC((f) => [...f.filter( frame=>frame.serialId !== frameSerialId)] )
      }, []);  
      const removeFromBoxingColumn = useCallback(( frameSerialId: string ) => {
            setBoxing((f) => [...f.filter( frame=>frame.serialId !== frameSerialId)] )
      }, []);  
      const removeFromShippingColumn = useCallback(( frameSerialId: string ) => {
            setShipping((f) => [...f.filter( frame=>frame.serialId !== frameSerialId)] )
      }, []);  
      const removeFromMillingColumn = useCallback(( frameSerialId: string ) => {
            setMilling((f) => [...f.filter( frame=>frame.serialId !== frameSerialId)] )
      }, []);  
      const removeFromFinalPrepColumn = useCallback( (frameSerialId: string ) => {
            setFinalPrep((f) => [...f.filter( frame=>frame.serialId !== frameSerialId)] )
      }, []);  
      const removeFromPremiereCompleted = useCallback( (frameSerialId: string ) => {
            setPremiereFrameCompleted((f) => [...f.filter( frame=>frame.serialId !== frameSerialId)] )
      }, []);  
      const removeFromAlloyCompleted = useCallback( (frameSerialId: string ) => {
            setAlloyFrameCompleted((f) => [...f.filter( frame=>frame.serialId !== frameSerialId)] )
      }, []);  

      function removeFromColumn (stationId: number, frameSerialId: string ){
            switch (stationId) {
                  case StationsEnum.cutting:
                        removeFromCuttingColumn( frameSerialId );
                        break;
                  case StationsEnum.boring:
                        removeFromBoringColumn( frameSerialId );
                        break;
                  case StationsEnum.coloring:
                        removeFromColoringColumn ( frameSerialId );
                        break;
                  case StationsEnum.pinsAndMagnets:
                        removeFromPinsAndMagnetsColumn ( frameSerialId );
                        break;
                  case StationsEnum.qualityControl:
                        removeFromQCColumn( frameSerialId );
                        break;
                  case StationsEnum.boxing:
                        removeFromBoxingColumn ( frameSerialId );
                        break;
                  case StationsEnum.shipping:
                        removeFromShippingColumn( frameSerialId );
                        break;
                  case StationsEnum.premiereAwaitingShipping:
                        removeFromShippingColumn( frameSerialId );
                        break;
                  case StationsEnum.alloyCutting: 
                        removeFromCuttingColumn( frameSerialId );
                        break;
                  case StationsEnum.milling:
                        removeFromMillingColumn( frameSerialId );
                        break;
                  case StationsEnum.finalPrep:
                        removeFromFinalPrepColumn ( frameSerialId );
                        break;
                  case StationsEnum.boxingAlloy:
                              removeFromBoxingColumn ( frameSerialId );
                              break;                        
                  case StationsEnum.premiereInventory:
                        removeFromPremiereCompleted ( frameSerialId );
                        break;                        
                  case StationsEnum.alloyInventory:
                        removeFromAlloyCompleted ( frameSerialId );
                        break;                        
                  default:
                  // code block
            }
      }

      const  addFrameToColumn = useCallback(   ( stationId: number, frame: FIP ) => {
            switch (stationId) {
                  case StationsEnum.cutting:
                        setCutting( frames =>  {
                              return [...frames, frame];
                        });
                        break;
                  case StationsEnum.boring:
                        setBoring( frames =>  {
                              return [...frames, frame];
                        });
                        break;
                  case StationsEnum.coloring:
                        setColoring( frames =>  {
                              return [...frames, frame];
                        });
                        break;
                  case StationsEnum.pinsAndMagnets:
                        setPingsAndMagnets( frames =>  {
                              return [...frames, frame];
                        });
                        break;
                  case StationsEnum.qualityControl:
                        setQC( frames =>  {
                              return [...frames, frame];
                        });
                        break;
                  case StationsEnum.boxing:
                        setBoxing( frames =>  {
                              return [...frames, frame];
                        });
                        break;
                  case StationsEnum.shipping:
                        setShipping( frames =>  {
                              return [...frames, frame];
                        });
                        break;
                  case StationsEnum.premiereAwaitingShipping:

                        break;
                  case StationsEnum.alloyCutting:
                        setCutting( frames =>  {
                              return [...frames, frame];
                        });
                        break;

                  case StationsEnum.milling:
                        setMilling( frames =>  {
                              return [...frames, frame];
                        });
                        break;
                  case StationsEnum.finalPrep:
                        setFinalPrep( frames =>  {
                              return [...frames, frame];
                        });
                        break;
                  case StationsEnum.boxingAlloy:
                              setBoxing( frames =>  {
                                    return [...frames, frame];
                              });
                              break;                        
                  case StationsEnum.premiereInventory:
                        setPremiereFrameCompleted( frames =>  {
                              return [...frames, frame];
                        });
                        break;    
                  case StationsEnum.alloyInventory:
                        setPremiereFrameCompleted( frames =>  {
                              return [...frames, frame];
                        });
                        break;                                                  
                  default:
                  // code block
            }
      }, []);  
      
      function sortFrameColumn(a: FIP, b: FIP) {
            ///return negative if the first item is smaller; positive if it it's larger, or zero if they're equal.
            if ( a.workState || b.workState  ) {
                  if ( a.workState && b.workState && a.workState === b.workState){
                        return 0;
                  } else if ( a.workState && b.workState === undefined ) {
                        return -1;
                  } else if ( a.workState === undefined && b.workState ) {
                        return 1;
                  } else if ( a.workState && b.workState && a.workState !== b.workState){ 
                        if ( a.workState === "STARTED" ){
                              return -1
                        } else {
                              return -1;
                        }
                  }
            }

            if (a.orderNumber && !b.orderNumber) {
                  return -1
            }

            if (!a.orderNumber && b.orderNumber) {
                  return 1
            }
            if (a.orderNumber && b.orderNumber) {
                  if (a.orderPriorityId === "2") {
                        return 1
                  } else {
                        return -1
                  }
            }

            // if both entries have an order bubble up by priority

            // otherwise sort baseed on creation date, oldest to newest
            //                 20     18 = 2 = a after b 
            return Date.parse(a.createdDateLocal) - Date.parse(b.createdDateLocal);

      }
      function search( searchString: string) {
            function isFound( frame: FIP ) {
                  if ( searchString === "") {
                        return true
                  } else if (frame.serialId.includes( searchString )) {
                        return true
                  } else if ( frame.ticket && frame.ticket.orderNumber.includes( searchString )){
                        
                  } else if ( frame.name.toLowerCase().includes( searchString.toLowerCase() )) {
                        return true;
                  } 
                  return false;
            }

            for ( let station of allStores ) {
                 let newState = station.store.map( frame => { 
                        frame.isVisible = isFound( frame );
                        return frame;
                 });
                 station.setHook( hook => {
                  return newState;
                 });
            }
      }
      //  if (!framesInProgress) return null;
/*

                        */
      useEffect(() => {
            if ( socket.connected ) {
                  socket.disconnect();
            } else {
                  socket.connect();
            }
      }, [manualDisconnect]);


      function getColumnPosition( stationId: number ) : number {
            let x: number | undefined = 0;
            switch(stationId) { 
                  case StationsEnum.cutting: { 
                        x = cuttingColumnRef.current?.getBoundingClientRect().x
                  break; 
                  } 
                  case StationsEnum.boring: { 
                        x = boringColumnRef.current?.getBoundingClientRect().x;
                  break; 
                  } 
                  case StationsEnum.coloring: { 
                        x = coloringColumnRef.current?.getBoundingClientRect().x;
                        break; 
                  } 
                  case StationsEnum.pinsAndMagnets: { 
                        x = pinsAndMagnestsColumnRef.current?.getBoundingClientRect().x;
                        break; 
                  }           
                  case StationsEnum.qualityControl: { 
                        x = qcColumnRef.current?.getBoundingClientRect().x;
                        break; 
                  } 
                  case StationsEnum.boxing: { 
                        x = boxingColumnRef.current?.getBoundingClientRect().x;
                        break; 
                  }   
                  case StationsEnum.boxingPrep: { 
      //                  boxingPr.current?.getBoundingClientRect().x)
                        break; 
                  }                           
                  case StationsEnum.premiereInventory: { 
                        x = premiereFrameCompletedColumnRef.current?.getBoundingClientRect().x;
                        break; 
                  }                            
                  case StationsEnum.premiereAwaitingShipping: { 
      //                  premi.current?.getBoundingClientRect().x)
                        break; 
                  }        
                  case StationsEnum.alloyCutting: { 
                        x = cuttingColumnRef.current?.getBoundingClientRect().x;
                        break; 
                  }                           
                  case StationsEnum.milling: { 
                        x = millingColumnRef.current?.getBoundingClientRect().x;
                        break; 
                  }                                    
                  case StationsEnum.finalPrep: { 
                        x = finalPrepColumnRef.current?.getBoundingClientRect().x;
                        break; 
                  }                        
                  case StationsEnum.boxingAlloy: { 
                        x = boxingColumnRef.current?.getBoundingClientRect().x;
                        break; 
                  }                          
                  case StationsEnum.alloyInventory: { 
                        x = alloyFrameCompletedColumnRef.current?.getBoundingClientRect().x;
                        break; 
                  }                          
                  case StationsEnum.shipping: {
                        x = shippingColumnRef.current?.getBoundingClientRect().x;
                        break;
                  }
                  default: { 
            
                  break; 
                  } 
            } 
            return x ?? 0;
      }
                // console.log( "Base URL: " + process.env.NODE_ENV +  Config )
      return (

            <div className="App">
                  {isConnected === false && 
                        <div id="connection-status"> 
                              CONNECTION LOST ... attemping reconnect... {connectionError}
                        </div>
                  }
           
                  <button className={"connect-toggle-btn" } onClick={()=> setManualDisconnect( curValue =>  !curValue) }>Toggle {manualDisconnect === true ? "Disconnected" : "Connected"}</button>
                    
                  <ConnectionIndicator connectionStatus={connectionState} transport={connectionTransport} connectionError={connectionError} clientId={clientId} />
                  <FloatingSearchBar searchFilter={search}/>
                  <TodaysTally premiereTotal={premiereTally} alloyTotal={alloyTally}/>
                  <div id="frame-columns">
                        { inInventoryMode === true && <div style={{width:"100%", textAlign: "center", color:"green"}}> START INVENTORY SCANNING</div>}
                        {frameType === FrameTypesEnum.premiere && <>
                              <div>
                                    <div className="active-batches" >
                                          <div className="column-title">Pending Tickets</div>
                                          <div className="station-totals">
                                                      <PendingCuttingTicket ticketColor={"#2986cc"} description={"Low"} total={cuttingLowPriorityTicketsTotal}/>
                                                      <PendingCuttingTicket ticketColor={"#FF0000"} description={"High"} total={cuttingHighPriorityTicketsTotal}/>
                                          </div>
                                    </div>
                                    <ActiveBatches batches={activeBatches} />
                                    <StationColumn2  ref={cuttingColumnRef} stationTotals={cuttingTotals} title={"Cutting"} users={stationActivity.filter( users => users.stationId === StationsEnum.cutting)} framesInProgress={cutting} inInventoryMode={inInventoryMode} removeFromColumn={removeFromColumn} addFrameToColumn={addFrameToColumn} getColumnPosition={(getColumnPosition)}/>
                              </div>
                              <StationColumn2 ref={boringColumnRef} title={"Boring"} stationTotals={boringTotals} users={stationActivity.filter( users => users.stationId === StationsEnum.boring)} framesInProgress={boring} inInventoryMode={inInventoryMode}  removeFromColumn={removeFromColumn} addFrameToColumn={addFrameToColumn} getColumnPosition={(getColumnPosition)}/>
                              <StationColumn2 ref={coloringColumnRef} title={"Coloring"} stationTotals={coloringTotals} users={stationActivity.filter( users => users.stationId === StationsEnum.coloring)} framesInProgress={coloring} inInventoryMode={inInventoryMode}  removeFromColumn={removeFromColumn} addFrameToColumn={addFrameToColumn} getColumnPosition={(getColumnPosition)}/>
                              <StationColumn2 ref={pinsAndMagnestsColumnRef} stationTotals={pinsAndMagnetsTotals} title={"Pins & Magnets"} users={stationActivity.filter( users => users.stationId === StationsEnum.pinsAndMagnets)} framesInProgress={pinsAndMagnets} inInventoryMode={inInventoryMode}  removeFromColumn={removeFromColumn} addFrameToColumn={addFrameToColumn} getColumnPosition={(getColumnPosition)}/>
                              <StationColumn2 ref={qcColumnRef} title={"QC"}  stationTotals={qcTotals} users={stationActivity.filter( users => users.stationId === StationsEnum.qualityControl)} framesInProgress={qc} inInventoryMode={inInventoryMode}  removeFromColumn={removeFromColumn} addFrameToColumn={addFrameToColumn} getColumnPosition={(getColumnPosition)}/>
                              <StationColumn2 ref={boxingColumnRef} title={"Boxing"} stationTotals={boxingTotals} users={stationActivity.filter( users => users.stationId === StationsEnum.boxing)} framesInProgress={boxing} inInventoryMode={inInventoryMode}  removeFromColumn={removeFromColumn} addFrameToColumn={addFrameToColumn} getColumnPosition={(getColumnPosition)}
                                    childSubHeaderComp={<BoxingPrepTotals inventoryTotal={boxingPrepInventoryTotal} />} 
                              />
                              <StationColumn2 ref={shippingColumnRef} title={"Tickets to Ship"} stationTotals={shippingTotals} users={ [] } framesInProgress={shipping} inInventoryMode={inInventoryMode}  removeFromColumn={removeFromColumn} addFrameToColumn={addFrameToColumn} getColumnPosition={(getColumnPosition)}/>
                              <StationColumn2 ref={premiereFrameCompletedColumnRef} title={"Completed (today)"} stationTotals={premiereFrameCompletedTotals} users={ [] } framesInProgress={premiereFrameCompleted} inInventoryMode={inInventoryMode} removeFromColumn={removeFromPremiereCompleted} addFrameToColumn={addFrameToColumn}  getColumnPosition={(getColumnPosition)}/>
                        </>
                        }

                        {frameType === FrameTypesEnum.alloy && <>
                              <div>
                                    <ActiveBatches batches={activeBatches} />
                                    <StationColumn2 ref={cuttingColumnRef} title={"Cutting"} stationTotals={cuttingTotals}  users={stationActivity.filter( users => users.stationId === StationsEnum.alloyCutting)} framesInProgress={cutting} inInventoryMode={inInventoryMode}  removeFromColumn={removeFromColumn} addFrameToColumn={addFrameToColumn} getColumnPosition={(getColumnPosition)}/>
                              </div>
                              <StationColumn2 ref={millingColumnRef} title={"Milling"} stationTotals={millingTotals}  users={stationActivity.filter( users => users.stationId === StationsEnum.milling)} framesInProgress={milling} inInventoryMode={inInventoryMode}  removeFromColumn={removeFromColumn} addFrameToColumn={addFrameToColumn} getColumnPosition={(getColumnPosition)}/>
                              <StationColumn2 ref={finalPrepColumnRef} title={"FinalPrep"} stationTotals={finalPrepTotals} users={stationActivity.filter( users => users.stationId === StationsEnum.finalPrep)} framesInProgress={finalPrep} inInventoryMode={inInventoryMode}  removeFromColumn={removeFromColumn} addFrameToColumn={addFrameToColumn} getColumnPosition={(getColumnPosition)}/>
                              <StationColumn2 ref={boxingColumnRef} title={"Boxing"} stationTotals={boxingTotals} users={stationActivity.filter( users => users.stationId === StationsEnum.boxingAlloy)} framesInProgress={boxing} inInventoryMode={inInventoryMode}  removeFromColumn={removeFromColumn} addFrameToColumn={addFrameToColumn} getColumnPosition={(getColumnPosition)}/>
                              <StationColumn2 ref={alloyFrameCompletedColumnRef} title={"Completed (today)"} stationTotals={alloyFrameCompletedTotals} users={ [] } framesInProgress={alloyFrameCompleted} inInventoryMode={inInventoryMode} removeFromColumn={removeFromAlloyCompleted} addFrameToColumn={addFrameToColumn} getColumnPosition={(getColumnPosition)}/>
                        </>
                        }
                  </div>
            </div>

      )

}

export default App;