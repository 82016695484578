export enum StationsEnum  {   

      receiving = 1,
      inspection = 2,
      cutting = 3,
      assembly = 4,   //60
      boxingPrep = 5, // 71
      boxing = 6,
      warehouse = 7,
      shipping = 8,
      qualityControl = 9,
      boxingAlloy = 10,
      alloyCutting = 11,
      premiereInventory = 12,
      alloyInventory = 13,
      boring = 14,
      pinsAndMagnets = 15,
      milling =  16,
      premiereFullfillment = 17,
      alloyFullfillment = 18,
      finalPrep = 19,
      coloring = 20,

      premiereFrameCompleted = 25,
      alloyFrameCompleted = 26,
      
      // this is for tickets / frames after QC that can be shipped.  Not sure if this is a permanent station or temp so adding a high number so it doesn't interfere with sequential order if another station is added
      premiereAwaitingShipping = 90,

      premiereShipped = 23, 
      alloyShipped = 24,




    }