import React from "react"
import FIP from "./models/fip";
import FIPTileInner from "./fip-tile-inner";
import { motion} from 'framer-motion'

interface IFIPTileProps {
      fip: FIP;
      currentWork: any;
      removeFromColumn:  (stationId: number, frameSerialId: string) => void;
      getColumnPosition:  (stationId: number) => number;
      addFrameToColumn:  (stationId: number, frame: FIP ) => void;
}


// Wait for the slide entrance to complete before exiting
/*
const SlideInAndFadeout = () => {
  const [didEnter, setDidEnter] = React.useState(false)
  const onDidEnter = () => setDidEnter(true)
  return (
    <FadeOut active={didEnter}>
      <SlideInDown onComplete={onDidEnter}>🥧</SlideInDown>
    </FadeOut>
  )
}
*/
const FIPInventoryTile = (props: IFIPTileProps) => {


      return (
            <>
              { props.fip.isVisible === true && props.fip.workState === "Inventory"  && 
                        <motion.div 
                        initial = {{opacity: 0}}
                        animate = {{opacity: 1}}
      
                        transition={{ 
                              duration: 2,
                              delay: 0,
                              ease: "easeIn"
                        }} 
                   
                    >
                          <FIPTileInner key={ props.fip.serialId} fip={props.fip} currentWork={null}  addFrameToColumn={props.addFrameToColumn}  removeFromColumn={props.removeFromColumn} />               
                    </motion.div>
          
            }

            </>
      );



}

export default FIPInventoryTile;