import React from "react"
import searchicon from './assets/search-icon.png';

interface IFloatingSearchBar {
      searchFilter: (searchString: string) => void;
}


const FloatingSearchBar = (props: IFloatingSearchBar) => {
      const [toggle, setToggle] = React.useState<boolean>(false)
      const [searchString, setSearchString] = React.useState<string>("");
      const inputRef = React.useRef<HTMLInputElement>(null);

      function handleChange(e: React.FormEvent<HTMLInputElement>): void {
            setSearchString(e.currentTarget.value)
            props.searchFilter(e.currentTarget.value);

      };


      return (

            <div className="floating-search-bar">
                  <button
                        type="button"
                        onClick={() => setToggle(!toggle)}><img src={searchicon} /></button>
                  {toggle && (
                        <form>
                              <input autoFocus type="search" placeholder='Serial#, Ticket#, Style' value={searchString} onChange={handleChange} />
                        </form>
                  )}

            </div>

      )



}

export default FloatingSearchBar;
