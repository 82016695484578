import iFIP from "./ifip";
import Ticket from "./ticket";

export default class FIP implements iFIP{
      constructor( data: iFIP ) {
            Object.assign( this, data )
            
      }
      id!: string;
      serialId!: string;
      productId!: string;
      name!: string;
      size!: string;
      year!: number;
      sku!: string;
      image!: string;
      style!: string;
      profileId!: string;
      profile!: string;
      barCode!: string;
      skuInternal!: string;
      quantity: number = 1;
      frameStyleId!: string;
      curStationId!: number;
      curStationDateTime!: string;
      isRecut?: boolean | undefined;
      isReturned?: boolean | undefined;
      isVoided?: boolean | undefined;
      returnedDateLocal?: string | undefined;
      recutDateLocal?: string | undefined;
      orderNumber?: string;
      orderEnteredDate?: string;
      shipByDate?: string;
      orderPriorityId?: string;
      orderPriorityDescription?: string;
      orderSourceId?: string;
      orderSourceDescription?: string;
      shippingServiceLevelId?: string;
      shippingServiceLevelDescription?: string;
      createdDateLocal!: string;
      ticket?: Ticket | undefined;
      workState?: string | undefined;
      workStartTime?: string | undefined;
      isVisible: boolean = true;
      userName?: string;

      nextStationId? : number;
      returnToStationIfCanceled?: number;  //used internally in the UI to track a canceled frame start, if the frame was pulled from a column other than the one the start event was triggerred in
      scannedInInventory: boolean  = false;
      scannedInCorrectColumn?: boolean

}
