import iFIP from "../models/ifip";

export enum FIPMessageTypes {
      /// these will likely be a subset of the frame history events? should use those instead?
      FRAME_START = "STARTED",
      FRAME_END = "COMPLETED",
      FRAME_VOIDED = "VOIDED",
      FRAME_UNVOIDED = "UNVOIDED",
      FRAME_REJECTED = "REJECTED",
      FRAME_START_CANCELED = "START_CANCELED",
      FRAME_CREATED = "FRAME CREATED", // used for batches of frames
      FRAME_REJECTED_HERE = "REJECTED HERE", // this is client side only
      ADDED_FROM_OTHER_STATION = "ADDED_FROM_OTHER_STATION",  // this is client side only
      FRAME_SHIPPED = "FRAME_SHIPPED"
}

export class FIPMessageBase {
      messageType: string
      constructor( messageType: FIPMessageTypes) {
            this.messageType = messageType.valueOf()
      }
}

// most of these will be triggered by a scanner event -- 
// listing out here
//  frame started at station - 
//  frame ended at station - moving to next / other station
//  frame rejected at station - moveing to another station
//  frame voided
//  frame unvoided
//   .. label printing.. not applicable
//  .. history lookups .. no applicable
// .. frame destroyed what else??
export class FIPMessage extends FIPMessageBase {
      constructor( messageType : FIPMessageTypes, frameSerialId: string, curStation: number, nextStation?: number, wasVoided?: boolean, wasUnvoided?: boolean, isReturn?: boolean, fip?: iFIP, batchFIP?: iFIP[], removeAfterAnimation?: boolean, userName?: string ) {
            super( messageType )
            this.frameSerialId = frameSerialId;
            this.curStation = curStation;
            this.nextStation = nextStation;
            this.wasVoided = wasVoided;
            this.wasUnvoide = wasUnvoided;
            this.isReturn = isReturn;
            this.fip = fip;
            this.removeAfterAnimation = removeAfterAnimation;
            this.batchFIP = batchFIP;
            this.userName = userName;
      }

      curStation: number; //where the frame currently is
      nextStation?: number;  // where it is being moved to if applicable
      frameSerialId: string;
      // potential state changes
      wasVoided?: boolean;
      wasUnvoide?: boolean;
      isReturn?: boolean; //??
      fip?: iFIP;
      batchFIP?: iFIP[]; // for adding a batch of new frames (from cutting)

      removeAfterAnimation?: boolean  = false;
      userName?: string;
    

}