import React from "react"

interface ITodaysTallyProps {
      premiereTotal: number;
      alloyTotal: number;
}


const TodaysTally = (props: ITodaysTallyProps) => {
      const [ isToggled, setIsToggled] = React.useState<boolean>(false)

      const rotate = isToggled ? "rotate(180deg)" : "rotate(0)"

      return (

            <div className="todays-tally-wrapper" >
                  { isToggled ?  
                        <>
                        <div className="title" style={{fontSize: "48px"}}>Today's Tally:  
                                    <div className="chevron-border" >
                                    <div className="chevron" style={{ transform: rotate, transition: "all 0.2s linear" }} onClick={()=>{ setIsToggled( curValue => !curValue)}}> 
                                           &#8963;

                                    </div>
                                    </div>
                        
                        </div>
                        </>
                        :
                        <>
                        <div className="title">Today's Tally:  
                                    <div className="chevron-border" >
                                    <div className="chevron" style={{ transform: rotate, transition: "all 0.2s linear" }} onClick={()=>{ setIsToggled( curValue => !curValue)}}> 
                                    &#8963;

                                    </div>
                                    </div>
                        
                        </div>
                        <div className="dept">Premiere:
                              { props.premiereTotal === 0 ? 
                                    <div className="total zero-total">{props.premiereTotal}</div>
                                    :
                                    <div className="total gtr-thn-zero-total">{props.premiereTotal}</div>
                              }
                        </div>
                        <div className="dept">Alloy:
                        { props.premiereTotal === 0 ? 
                                    <div className="total zero-total">{props.alloyTotal}</div>
                                    :
                                    <div className="total gtr-thn-zero-total">{props.alloyTotal}</div>
                              }
                        </div>

                        <div className="dept">
                              { props.premiereTotal === 0 && props.alloyTotal === 0 ? 
                                    <div className="total zero-total">{props.alloyTotal}</div>
                                    :
                                    <div className="total gtr-thn-zero-total">{props.alloyTotal + props.premiereTotal}</div>
                              }
                        </div>
                        </>
                  }
            </div>

      )



}

export default TodaysTally;
