
import React from "react";
import FIPTile2 from "./fip-tile2";
import FIP from "./models/fip";
import { StationActivity } from "./models/station-activity";
import StationUserTile from "./station-user-tile";
import { motion, LayoutGroup } from "framer-motion"
import FIPInventoryTile from "./fip-inventory-tile";
import { IStationColumnTotals } from "./station-column-totals";
import StationColumnTotals from "./station-column-totals"
import { StationsEnum } from "./station-enums";
import BoxingPrepTotals from "./boxing-prep";

interface IStationColumnProps {
      title: string;
      framesInProgress: FIP[];
      users: StationActivity[];
      removeFromColumn: any;
      addFrameToColumn: any;
      inInventoryMode: boolean;
      stationTotals: IStationColumnTotals | undefined;
      getColumnPosition: (stationId: number) => number;
      childSubHeaderComp?: React.ReactNode;
}
//const InputText = React.forwardRef<HTMLInputElement, IInputProps>((props, ref) => (
const StationColumn2 = React.forwardRef<HTMLDivElement, IStationColumnProps>((props, ref) => {
      
      return (
            <div ref={ref} className="frame-column">
                  <div className="column-title">{props.title} <div className="column-count">{props.framesInProgress ? props.framesInProgress.length : 0}</div></div> 
                  <StationColumnTotals stationTotals={props.stationTotals} />
                  { props.childSubHeaderComp && 
                         <div>{props.childSubHeaderComp}</div>
                  }
                  <div className="frame-list">
                        <LayoutGroup>
                              { props.users.map ( ( user: StationActivity ) => {
                                    return <StationUserTile key={user.userId} user={user} />
                                    })
                              }

                              { props.inInventoryMode === false &&  props.framesInProgress.map ( ( fip: FIP ) => {
                                          return <FIPTile2 key={fip.serialId } fip={fip} currentWork={null} removeFromColumn={props.removeFromColumn} getColumnPosition={props.getColumnPosition} addFrameToColumn={props.addFrameToColumn } />
                                    })
                              }

                              { props.inInventoryMode === true &&  props.framesInProgress.map ( ( fip: FIP ) => {
                                          return <FIPInventoryTile key={fip.serialId } fip={fip} currentWork={null} removeFromColumn={props.removeFromColumn} getColumnPosition={props.getColumnPosition} addFrameToColumn={props.addFrameToColumn } />
                                    })
                              }
                        </LayoutGroup>
                  </div>
            </div>
      )
      
});

export default StationColumn2;